import React from "react";

const Footer = () => {
	return (
		<footer className="w-full font-sans text-sm text-primary md:my-3 lg:my-4">
			<div className="container mx-auto px-2 lg:px-11 h-full xl:max-w-1100">
				<div className="h-full w-full max-w-5xl flex items-center">
					<p className="text-sm font-normal">
						&copy; {new Date().getFullYear()} Doktor Simeonsi Dieet. Kõik
						õigused kaitstud.
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;