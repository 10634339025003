import {routes} from "../data/routes";

export const checkMetaTitle = (location) => {
	const defaultMetaTitle = 'Тugikeskus'

	switch (location) {
		case routes.index:
			return "Dr Simeonsi dieedi tugikeskus"
		case routes.login:
			return `${defaultMetaTitle} - Logi sisse`
		case routes.diary:
			return `${defaultMetaTitle} - Päevik`
		case routes.feedback:
			return `${defaultMetaTitle} - Teade`
		case routes.resources:
			return `${defaultMetaTitle} - Laadi alla`
		case routes.guide:
			return `${defaultMetaTitle} - Dieedijuhend`
		case routes.packages:
			return `${defaultMetaTitle} - Soodustused`
		default:
			return defaultMetaTitle
	}
}