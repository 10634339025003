import React from 'react';
import {Helmet} from 'react-helmet';

const Head = ({title}) => {
	return (
		<>
			<Helmet
				defaultTitle="Dr Simeonsi dieedi tugikeskus"
				title={title}
			/>
		</>
	)
}

export default Head;
