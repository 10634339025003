import React, {useContext} from "react";

import {Link, navigate} from "gatsby";

import {authContext} from "../contexts/authContext";

const MobileMenu = (props) => {
	const auth = useContext(authContext);
	const linkClassName = "m-0 cursor-pointer hover:text-green mt-5";

	function handleLogoutBtn(event) {
		event.preventDefault();
		navigate(`/`);
		setTimeout(() => auth.logout(() => {
		}), 1000);
	}

	function isLinkActive(path) {
		return path === props.pathname;
	}

	return (
		<>
			<div
				className={
					"bg-black bg-opacity-10 fixed left-0 top-0 h-full w-full m-0 z-20"
				}
				onKeyDown={props.toggleOpened}
				onClick={props.toggleOpened}
				role={"button"}
				tabIndex={0}
			>
				<div
					className={
						"bg-white h-full w-9/12 flex flex-col text-xl items-center"
					}
					role={"button"}
					tabIndex={0}
					onKeyDown={(e) => e.preventDefault()}
					onClick={(e) => e.preventDefault()}
				>
					<Link
						to={"/"}
						className={linkClassName + (isLinkActive("/") ? " text-green" : "")}
					>
						Tugikeskus
					</Link>
					<Link
						to={"/app/guide"}
						className={
							linkClassName +
							(isLinkActive("/app/guide") || isLinkActive("/app/guide/")
								? " text-green"
								: "")
						}
					>
						Dieedijuhend
					</Link>
					<Link
						to={"/app/feedback"}
						className={
							linkClassName +
							(isLinkActive("/app/feedback") || isLinkActive("/app/feedback/")
								? " text-green"
								: "")
						}
					>
						Teade
					</Link>
					{auth.isLoggedIn ? (
						<Link
							to={"/app/diary"}
							className={
								linkClassName +
								(isLinkActive("/app/diary") || isLinkActive("/app/diary/")
									? " text-green"
									: "")
							}
						>
							Päevik
						</Link>
					) : (
						<Link
							to={"/diary"}
							className={
								linkClassName +
								(isLinkActive("/diary") || isLinkActive("/diary/")
									? " text-green"
									: "")
							}
						>
							Päevik
						</Link>
					)}
					<Link
						to={"/app/resources"}
						className={
							linkClassName +
							(isLinkActive("/app/resources") || isLinkActive("/app/resources/")
								? " text-green"
								: "")
						}
					>
						Laadi alla
					</Link>
					<Link
						to={"/packages"}
						className={
							linkClassName +
							(isLinkActive("/packages") || isLinkActive("/packages/")
								? " text-green"
								: "")
						}
					>
						Soodustused
					</Link>
					<a href={"https://www.simeonsidieet.ee/"} className={linkClassName}>
						Avaleht
					</a>
					{auth.isLoggedIn ? (
						<Link to={"/"} className={linkClassName} onClick={handleLogoutBtn}>
							Logi välja
						</Link>
					) : (
						<Link
							to={"/login"}
							className={
								linkClassName +
								(isLinkActive("/login/") || isLinkActive("/login")
									? " text-green"
									: "")
							}
						>
							Logi sisse
						</Link>
					)}
				</div>
			</div>
		</>
	);
};

export default MobileMenu