import React, {useContext} from "react";

import {authContext} from "../contexts/authContext";

import PropTypes from "prop-types";

import LoaderPage from "./LoaderPage";

const Section = ({title, children}) => {
	const auth = useContext(authContext);

	return (
		<div className={"w-full"}>
			{auth.isLoading ? (
				<LoaderPage/>
			) : (
				<section
					className={
						"bg-white w-full max-w-5xl m-auto rounded-md flex flex-col items-center pb-10 mt-6 px-2 md:px-10 text-xl sm:text-2xl"
					}
				>
					<h1
						className={
							"font-sans font-semibold text-center text-sm md:text-xl md:text-2xl md:mt-10 max-w-2xl"
						}
					>
						{title}
					</h1>
					{children}
				</section>
			)}
		</div>
	);
};

Section.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Section
