import React from "react";

import {Loader} from "@mantine/core";

const LoaderPage = (props) => {
	return (
		<section
			className={
				"bg-white w-full max-w-5xl m-auto rounded-md flex flex-col items-center pb-10 mt-6 px-2 md:px-10 text-xl sm:text-2xl"
			}
		>
			<Loader size={"lg"} className={"mt-10"}/>
		</section>
	);
};

export default LoaderPage;