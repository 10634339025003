import React, {useContext, useState} from "react"

import {Link, navigate} from "gatsby"

import {Burger} from '@mantine/core';

import {authContext} from "../contexts/authContext";

import MobileMenu from "./MobileMenu";

import logo from '../images/logo.png'

const Header = (props) => {
	const auth = useContext(authContext);
	const linkClassName = "m-0 cursor-pointer hover:text-green";

	const [opened, setOpened] = useState(false);
	const title = opened ? 'Close navigation' : 'Open navigation';

	function toggleOpened() {
		setOpened((o) => !o)
	}

	function handleLogoutBtn(event) {
		event.preventDefault();
		navigate(`/`)
		setTimeout(() => auth.logout(() => {
		}), 1000)
	}

	function isLinkActive(path) {
		return path === props.location?.pathname
	}

	return (
		<header>
			<div
				className={"max-w-5xl m-auto flex items-center md:items-start lg:items-center md:flex-col justify-between text-base leading-5 font-sans text-primary px-3 lg:flex-row md:text-sm lg:text-base"}>
				<Link to={'/'} className={'w-286'}><img src={logo} alt={"Logo"} className={'w-full'}/></Link>
				<div className={"hidden md:flex  justify-between items-center w-full m-0 lg:ml-10 md:mb-3 lg:mb-0"}>
					<Link to={'/'}
						  className={linkClassName + (isLinkActive('/') ? ' text-green' : '')}>Tugikeskus</Link>
					<Link to={'/app/guide'}
						  className={linkClassName + (isLinkActive('/app/guide') || isLinkActive('/app/guide/') ? ' text-green' : '')}>Dieedijuhend</Link>
					<Link to={'/app/feedback'}
						  className={linkClassName + (isLinkActive('/app/feedback') || isLinkActive('/app/feedback/') ? ' text-green' : '')}>Teade</Link>
					<Link to={'/app/diary'}
						  className={linkClassName + (isLinkActive('/app/diary') || isLinkActive('/app/diary/') ? ' text-green' : '')}>Päevik</Link>
					<Link to={'/app/resources'}
						  className={linkClassName + (isLinkActive('/app/resources') || isLinkActive('/app/resources/') ? ' text-green' : '')}>Laadi
						alla</Link>
					<Link to={'/packages'}
						  className={linkClassName + (isLinkActive('/packages') || isLinkActive('/packages/') ? ' text-green' : '')}>Soodustused</Link>
					<a href={'https://www.simeonsidieet.ee/'} className={linkClassName}>Avaleht</a>
					{auth.isLoggedIn
						? <Link to={'/'} className={linkClassName} onClick={handleLogoutBtn}>Logi välja</Link>
						: <Link to={'/login/'}
								className={linkClassName + (isLinkActive('/login/') || isLinkActive('/login') ? ' text-green' : '')}>Logi
							sisse</Link>}
				</div>

				<div className={"block md:hidden m-0"}>
					<Burger opened={opened} onClick={toggleOpened} title={title}/>
					{opened ? <MobileMenu pathname={props.location.pathname} toggleOpened={toggleOpened}/> : ''}
				</div>
			</div>
		</header>
	)
}

export default Header
