import React from "react";

import {MantineProvider} from "@mantine/core";

const WithProvider = (props) => {
	return (<MantineProvider
			theme={{
				fontFamily: "Montserrat", primaryColor: "lime",
			}}
		>
			{props.children}
		</MantineProvider>);
};

export default WithProvider;